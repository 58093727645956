<!--
 * @Description: 设备入仓弹框
 * @Author: ChenXueLin
 * @Date: 2021-10-19 14:27:25
 * @LastEditTime: 2022-07-01 09:27:30
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="deviceDialogType == 1 ? '设备入仓' : '移出设备'"
    :visible.sync="addDeviceDialog"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    custom-class="edit-customer-dialog"
  >
    <table-title>
      <template v-slot:searchForm>
        <el-form class="search-list" ref="searchForm" :model="searchForm">
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="设备编号"
              title="设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="商品名称"
              title="商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:operateAction>
        <el-button type="primary">导入设备</el-button>
        <i
          v-show="deviceDialogType == 1"
          class="e6-icon-abnormal_line e6Icon"
          title="注意：仅可添加未安装设备"
        ></i>
      </template>
    </table-title>

    <el-table
      border
      height="300px"
      :data="tableData"
      highlight-current-row
      class="elTable"
    >
      <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
    </el-table>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleClose">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";

export default {
  name: "",
  components: {},
  props: {
    addDeviceDialog: {
      type: Boolean,
      default: false
    },
    deviceDialogType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      columnData: [
        {
          fieldName: "label",
          display: true,
          fieldLabel: "商品ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "renwudandao",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "kehumingcheng",
          display: true,
          fieldLabel: "商品分类",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "kehujiancheng",
          display: true,
          fieldLabel: "设备编号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      searchForm: {
        orderNo: ""
      },
      tableData: [
        {
          label: "1111"
        }
      ]
    };
  },
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    handleClose() {
      this.$emit("handleClose", "addDeviceDialog");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped></style>
